import React, { useState, useEffect } from 'react';
import { Settimane, getStartOfWeek, getEndOfWeek } from './helper/settimane';
import Select from 'react-select';
import { Button, Container, Row, Col, Alert, Form } from 'react-bootstrap'
import { SpinnerComp } from './helper/spinner';
import { getDatiTabella } from './handlers/datiTabellaHandler'
import Tabella from './Tabella';
import { buildUrl } from "./helper/handler"
import { getFromApi } from './utilities/NetUtilities';

function TabellaGenerale (props) {
    const [datiTabellaFatturato, setDatiTabellaFatturato] = useState([]);
    const [datiTabellaMedia, setDatiTabellaMedia] = useState([]);
    const [datiTabellaVariazione, setDatiTabellaVariazione] = useState([]);
    const [datiTabellaMedia730, setDatiTabellaMedia730] = useState([]);
    const [datiRiepilogoGenerale, setDatiRiepilogoGenerale] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [numSettimanaInizio, setNumSettimanaInizio] = useState("1");
    const [numSettimanaFine, setNumSettimanaFine] = useState("53");
    const [zona, setZona] = useState([]);
    const [optionsZone, setOptionsZone] = useState([]);
    const [selectedOptionSettimanaInizio, setSelectedOptionSettimanaInizio] = useState({ label: `settimana 1 (${getStartOfWeek(1)} - ${getEndOfWeek(1)})`, value: "1" });
    const [selectedOptionSettimanaFine, setSelectedOptionSettimanaFine] = useState({ label: `settimana 53 (${getStartOfWeek(53)} - ${getEndOfWeek(53)})`, value: "53" });
    const [selectedOptionZona, setSelectedOptionZona] = useState(null);
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");
    const [message, setMessage] = useState({});
    const [emptydata, setEmptydata] = useState("");
    const [checked, setChecked] = useState(false);

    const allYears = (e) => {
        setLoading(true);
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        connessioneBack("1", "53", "", organizzazioneMap.nome, organizzazioneMap.tipo, !checked);
        setChecked(!checked);
    };

    useEffect(() => {
        setLoading(true);
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        setNomeOrganizzazione(organizzazioneMap.nome);
        setTipoOrganizzazione(organizzazioneMap.tipo);
        if (organizzazioneMap) {
            connessioneBack("1", "53", "", organizzazioneMap.nome, organizzazioneMap.tipo, false);
        }
    }, []);

    const connessioneBack = (inizio, fine, zona, nome, tipo, tutti) => {
        const okAction = (data) => {
            setLoading(false);
            if (data.emptytable) {
                setEmptydata(data.emptytable[0]);
            } else {
                const {
                    datiTabellaFatturato,
                    datiTabellaMedia,
                    datiTabellaVariazione,
                    datiTabellaMedia730,
                    datiRiepilogoGenerale,
                    errore,
                    optionsZone
                } = getDatiTabella(
                    data,
                    props.numTabella,
                    props.nome,
                    props.riepilogogenerale, inizio, fine, zona, nome, tipo, tutti
                );
                setDatiTabellaFatturato(datiTabellaFatturato);
                setDatiTabellaMedia(datiTabellaMedia);
                setDatiTabellaVariazione(datiTabellaVariazione);
                setDatiTabellaMedia730(datiTabellaMedia730);
                setDatiRiepilogoGenerale(datiRiepilogoGenerale);
                setError(errore);
                setOptionsZone(optionsZone);
                if (data.warning) {
                    setLoading(false);
                    setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione });
                }
            }
        };
        const nokAction = (data) => {
            setLoading(false);
            setMessage({ tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione });
        };
        const url = getUrl(inizio, fine, zona, nome, tipo, tutti);
        getFromApi(url, okAction, nokAction);
    };

    const getUrl = (inizio, fine, zona, nome, tipo, tutti) => {
        var paramsObject = {
            organizzazione: nome,
            tipo: tipo,
            start: inizio,
            end: fine,
            zona: zona,
            tutti: tutti
        };
        return buildUrl(props.url, paramsObject);
    };

    const handleSettimaneInizio = (event) => {
        const inizio = event.value;
        setNumSettimanaInizio(inizio);
        setSelectedOptionSettimanaInizio(event);
    };

    const handleSettimaneFine = (event) => {
        const fine = event.value;
        setNumSettimanaFine(fine);
        setSelectedOptionSettimanaFine(event);
    };

    const handleZone = (event) => {
        const zone = event.map(element => element.value);
        setZona(zone);
        setSelectedOptionZona(event);
    };

    const handleFiltri = () => {
        setLoading(true);
        connessioneBack(numSettimanaInizio, numSettimanaFine, zona, nomeOrganizzazione, tipoOrganizzazione, checked);
    };

    if (loading) {
        return <SpinnerComp />;
    }

    return (
        <div className='main' role='main'>
            <Container fluid>
                <Alert variant={message.tipo} id="message" >
                    {message.descrizione}
                </Alert>
                <h1 id="titolo" className="d-flex justify-content-center mb-5">
                    {props.titolo} ({nomeOrganizzazione.toLowerCase()}/{tipoOrganizzazione.toLowerCase()})</h1>
                <Row className='filtri'>
                    <Col lg={3}>
                        <p className="font-weight-bold">inizio</p>
                        <Select value={selectedOptionSettimanaInizio} className="select" id="inizio"
                            onChange={handleSettimaneInizio} options={Settimane} aria-label='numero settimana inizio' />
                    </Col>
                    <Col lg={3}>
                        <p className="font-weight-bold">fine</p>
                        <Select value={selectedOptionSettimanaFine} className="select" id="fine"
                            onChange={handleSettimaneFine} options={Settimane} aria-label='numero settimana fine' />
                    </Col>
                    <Col lg={3}>
                        <p className="font-weight-bold">zona</p>
                        <Select value={selectedOptionZona} className="select" isMulti id="zona"
                            onChange={handleZone} options={optionsZone} aria-label='zone' />
                    </Col>

                    <Col lg={1}>
                        <Button className='btnGenerale' variant="flat sb-filtri" onClick={handleFiltri} size='sm' id="filtra">conferma</Button>
                        {/* <Button className='btnGenerale' variant="flat sb-filtri" size="sm" onClick={this.allYears}>TUTTI</Button> */}

                    </Col>
                    <Col lg={2}>
                        <Form.Check
                            inline
                            checked={checked}
                            className='ml-3 allYearsCheckbox allyear'
                            type="checkbox"
                            id="default-checkbox"
                            label="mostrare tutti gli anni"
                            onChange={e => allYears(e)} />
                    </Col>

                </Row>
                {emptydata !== "" ? <div className="space m-5">
                    <h2 style={{ marginLeft: "70px" }}>{emptydata}</h2></div> : <>
                    {datiRiepilogoGenerale.map((dati, key) => (
                        <div key={key} className="divTabel mt-5">
                            {dati.button}
                            <Tabella rows={dati.rows} intestazione={dati.intestazione}
                                open={true} nomeTabella={dati.nome} fixed={false} specialeTitle="titileZIndex" />
                        </div>
                    ))}
                    {datiTabellaFatturato.map((dati, key) => (
                        <div key={key} className="divTabel">
                            {dati.button}
                            <Tabella rows={dati.rows} intestazione={dati.intestazione}
                                open={true} nomeTabella={dati.nome} fixed={true} />
                        </div>
                    ))}
                    {datiTabellaMedia.map((dati, key) => (
                        <div key={key} className="divTabel">
                            {dati.button}
                            <Tabella rows={dati.rows} intestazione={dati.intestazione}
                                open={true} nomeTabella={dati.nome} fixed={true} />
                        </div>
                    ))}
                    {datiTabellaMedia730.map((dati, key) => (
                        <div key={key} className="divTabel">
                            {dati.button}
                            <Tabella rows={dati.rows} intestazione={dati.intestazione}
                                open={true} nomeTabella={dati.nome} fixed={true} />
                        </div>
                    ))}
                    {datiTabellaVariazione.map((dati, key) => (
                        <div key={key} className="divTabel">
                            {dati.button}
                            <Tabella rows={dati.rows} intestazione={dati.intestazione}
                                open={true} nomeTabella={dati.nome} fixed={true} />
                        </div>
                    ))}
                </>}
            </Container>
        </div>
    )
}

export default TabellaGenerale