import React from 'react';
import Tabella730 from './Tabella730';

function RiepilogoTotale() {
    return (
        <Tabella730 titolo="Riepilogo totale"
            url="riepilogototale"
            nome='RIEPILOGO_TOTALE'
            type="riepilogototale"
        />
    )
}

export default RiepilogoTotale