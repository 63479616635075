import React, { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import logo from '../logoacli.jpg'
import { buildUrl, saveUserToStorage } from '../helper/handler'
import { getFromApi } from '../utilities/NetUtilities'

function Login() {

    const [currentView, setCurrentView] = useState("logIn")
    const [message, setMessage] = useState([])
    const passwordRef = useRef()
    const usernameRef = useRef()
    const emailRef = useRef()
    const history = useHistory();

    const loginAction = (data) => {
        saveUserToStorage(data.user);
        history.push("/landingpage");
    }

    const loginError = (response) => {
        setMessage(response.messaggi[0]);
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("password");
    }

    const submitHandler = event => {
        event.preventDefault();
        if (usernameRef.current.validity.valid) {
            setMessage({ tipo: "", descrizione: "" });
            const username = usernameRef.current.value;
            const password = passwordRef.current.value;
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("password", password);
            const url = buildUrl("login");
            getFromApi(url, loginAction, loginError);
        } else {
            setMessage({ tipo: "warning", descrizione: "email non valida: <" + usernameRef.current.value + ">" });
        }
    }

    const resetHandler = event => {
        event.preventDefault();
        const url = buildUrl('askResetPassword', { "email": emailRef.current.value });
        const action = (response) => setMessage(response.messaggi[0]);
        getFromApi(url, action, action);
    }

    const view = () => {
        switch (currentView) {
            case "logIn":
                return (
                    <div className="w-100" style={{ maxWidth: "500px" }}>
                        <Card>
                            <Card.Body>
                                <Card.Img variant="top" src={logo} alt='logo' />
                                <h1 className='text-center mt-3'>Login</h1>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            required ref={usernameRef}
                                            aria-label='email'
                                            data-testid="username"
                                            id="username"
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>password</Form.Label>
                                        <Form.Control type="password" required ref={passwordRef} aria-label='password' data-testid="password" id="password" />
                                    </Form.Group>
                                    <Button
                                        className="btnGenerale w-100"
                                        variant="flat"
                                        type="submit"
                                        data-testid="submit-login"
                                        id="login"
                                        onClick={submitHandler}
                                    >Log In</Button>
                                </Form>
                                <div className="w-100 text-center mt-3">
                                    <Link to="/" onClick={() => setCurrentView("PWReset")} data-testid="PWReset" id="reset">password dimenticata?</Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                )
            case "PWReset":
                return (
                    <div className="w-100" style={{ maxWidth: "500px" }}>
                        <Card>
                            <Card.Body>
                                <Card.Img variant="top" src={logo} alt='logo' />
                                <h1 className='text-center mt-3'>Reset Password</h1>
                                <Form onSubmit={resetHandler}>
                                    <Form.Group>
                                        <Form.Label>email</Form.Label>
                                        <Form.Control type="email" ref={emailRef} aria-label='email' required data-testid="resetEmailField" id="email" />
                                    </Form.Group>
                                    <Button className="btnGenerale w-100" variant="flat" type="submit" id="resetPwd" data-testid="sendReset">Reset Password</Button>
                                </Form>
                                <div className="w-100 text-center mt-3">
                                    <Link to="/" onClick={() => setCurrentView("logIn")} data-testid="annullaReset" id="annulla">torna al login</Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                )
            default:
                break
        }
    }

    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh", maxWidth: "500px" }} role='main'>
            <div>
                {message && <Alert variant={message.tipo} id="message">{message.descrizione}</Alert>}
                {view()}
            </div>
        </Container>
    )
}

export default Login;
