import React, { useEffect, useState } from 'react';
import {daMinutiAOre} from './turniHelper';

function TabellaSede(props) {

    const [tabellaSede, setTabellaSede] = useState(null)
    const [totMinutiSede, setTotMinutiSede] = useState({})

    useEffect(() => {
        setTabellaSede(props.tabellaSede)
        setTotMinutiSede(props.minutiTotSede);
    }, [props.tabellaSede])

    const printRighe = (tabellaServizi, sede, indexSede) =>{
        const nServizi = Object.keys(tabellaServizi).length;
        return(
            Object.keys(tabellaServizi).map((servizio, indexServizio) => {
            var minuti = tabellaServizi[servizio]
            return (
            <tr id={indexSede-indexServizio} className='cellaSpaziosa'>
                {indexServizio===0 && <td rowSpan={nServizi+1 } id={'sede-' + indexSede + '-' + indexServizio} className="inEvidenza cellaSpaziosa">{sede}</td>}
                <td className='cellaSpaziosa' id={'servizio-' + indexSede + '-' + indexServizio}>{servizio}</td>
                <td className="center cellaSpaziosa" id={'ore-' + indexSede + '-' + indexServizio}>{daMinutiAOre(minuti)}</td>
            </tr>
            )}))
        
    }

    const printTotaleSede = (sede, indexSede) => {
        return (<tr>
                <td className="inEvidenza right cellaSpaziosa">totale ore</td>
                <td className="inEvidenza center cellaSpaziosa" id={'totore-' + indexSede}>{daMinutiAOre(totMinutiSede[sede])}</td></tr>
        )
    }

    const printTabella = () =>{        
       return( Object.keys(tabellaSede).map((sede, indexSede) => {
            var tabellaServizi = tabellaSede[sede];
            return (<>{printRighe(tabellaServizi, sede, indexSede)}                      
                {printTotaleSede(sede, indexSede)} </>)
            })) 
    }

    return (
        <div id="tabellasede" className='table-responsive'>
            <h2 className='inEvidenza'>Totale zona</h2>
            {tabellaSede && <table className="table-bordered bottom-space " >
                <thead className="App">
                    <tr key="intestazione">
                        <th className="inEvidenza cellaSpaziosa">sede</th>
                        <th className="inEvidenza cellaSpaziosa">servizio</th>
                        <th className="inEvidenza cellaSpaziosa">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                {printTabella()}
                </tbody>
            </table>}
        </div>
    )
}

export default TabellaSede;