import React from 'react';
import Tabella730 from './Tabella730';

function NumeroSettimanale730() {
    return (
        <Tabella730 titolo="Numero settimanale 730"
            url="numerosettimanale730"
            nome='NUMERO_SETTIMANALE_730'
            type="numerosettimanale730"
            format={true} />
    )
}

export default NumeroSettimanale730