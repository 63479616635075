
import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { TimePicker } from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import { Alert, Button } from 'react-bootstrap';
import moment from "moment";
import { buildUrl } from '../helper/handler';
import { postToApi } from '../utilities/NetUtilities';
import { getSede } from './turniHelper';

export function TurnoLavoratore(props) {

    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione")); 
        if(organizzazioneMap) {
            setNomeOrganizzazione(organizzazioneMap.nome);
            setTipoOrganizzazione(organizzazioneMap.tipo);
        }
    }, [])

    const buildSedeOption = (sede) => ({ label: sede.descrizione, value: sede.id });

    const getSedeOption = () => {
        var option;
        if (props.sede) {
            const sede = getSede(props.sedi, props.sede);
            option = buildSedeOption(sede);
        } else {
            option = null;
        }
        return option;
    }

    const buildPostazioneOption = (postazione, index) => (
        { label: postazione, value: index }
    )

    const getPostazioneOption = () => {
        var option;
        if (props.sede) {
            const sede = getSede(props.sedi, props.sede);
            const postazioni = sede.postazioni;
            if (postazioni) {
                const postazione = postazioni[props.postazione];
                option = buildPostazioneOption(postazione, props.postazione);
            } else {
                option = null;
            }
        } else {
            option = null;
        }
        return option;
    }

    const buildServizioOption = (servizio, index) => (
        { label: servizio, value: index }
    )

    const getServizioOption = () => {
        var option;
        if (props.servizio) {
            const servizio = props.servizi[props.servizio];
            option = buildServizioOption(servizio, props.servizio);
        } else {
            option = null;
        }
        return option;
    }

    const [message, setMessage] = useState([]);
    const [sede, setSede] = useState(getSedeOption());
    const [postazione, setPostazione] = useState(getPostazioneOption());
    const [servizio, setServizio] = useState(getServizioOption());
    const [inizio, setInizio] = useState(props.inizio);
    const [lavoratore, setLavoratore] = useState(props.lavoratore);
    const [fine, setFine] = useState(props.fine);

    const sediOptions = () => {
        return props.sedi.map((sede) => buildSedeOption(sede));
    }

    const sedeChanged = (sede) => {
        setSede(sede);
        setMessage([]);
        setPostazione(null);
    }

    const lavoratoreChanged = (lavoratore) => {
        setLavoratore(lavoratore);
        setMessage([]);
    }

    const postazioniOptions = () => {
        const sedeId = sede ? sede.value : null;
        const postazioni = getSede(props.sedi, sedeId).postazioni;
        var options = [];
        Object.entries(postazioni).map((postazione, index) => (
            options.push(buildPostazioneOption(postazione[1], postazione[0]))))
        return options;
    }

    const postazioneChanged = (postazione) => {
        setPostazione(postazione);
        setMessage([]);
    }

    const serviziOptions = () => {
        return Object.entries(props.servizi).map((servizio, index) => buildServizioOption(servizio[1], servizio[0]));
    }

    const servizioChanged = (servizio) => {
        setServizio(servizio);
        setMessage([]);
    }

    const inizioChanged = (inizio) => {
        setInizio(inizio);
        setMessage([]);
    }

    const fineChanged = (fine) => {
        setFine(fine);
        setMessage([]);
    }

    const setMessageFromApi = (data) => {
        setMessage([])
        data.messaggi.map((value, key) => (
            setMessage(message => [...message, {
                descrizione: value.descrizione,
                tipo: value.tipo.toLowerCase()
            }])
        ))
    }

    const saveTurno = () => {
        const giorno = moment(props.giorno).format('DD/MM/yyyy');
        const params = {
            id: props.id,
            lavoratore: lavoratore.value,
            giorno: giorno + " 00:00",
            servizio: servizio.value,
            sede: sede.value,
            postazione: postazione.value,
            inizio: inizio,
            fine: fine,
            mattina: props.mattina,
            organizzazione : nomeOrganizzazione,
            tipo: tipoOrganizzazione
        };
        const url = buildUrl('aggiornaTurno');
        postToApi(url, params, props.action, setMessageFromApi);
    }

    const salva = () => {
        if (lavoratore == null) {
            setMessage([{ descrizione: "scegliere il lavoratore", tipo: "danger" }]);
        } else if (sede == null) {
            setMessage([{ descrizione: "scegliere la sede", tipo: "danger" }]);
        } else if (postazione == null) {
            setMessage([{ descrizione: "scegliere la postazione", tipo: "danger" }]);
        } else if (servizio == null) {
            setMessage([{ descrizione: "scegliere il servizio", tipo: "danger" }]);
        } else if (inizio == null) {
            setMessage([{ descrizione: "scegliere l'ora di inizio", tipo: "danger" }]);
        } else if (fine == null) {
            setMessage([{ descrizione: "scegliere l'ora di fine", tipo: "danger" }]);
        } else {
            if (fine !== null && inizio !== null) {
                if (checkInizioFine()) {
                    saveTurno();
                } else {
                    setMessage(message => [...message, { descrizione: "l'ora di fine deve essere successiva a quella di inizio", tipo: "danger" }]);
                }
            }
        }
    }

    const elimina = () => {
        const params = {
            id: props.id
        };
        const url = buildUrl('eliminaTurno');
        postToApi(url, params, props.eliminaAction, setMessageFromApi);
    }

    const checkInizioFine = () => {
        const oreMinutiFine = fine.split(":");
        const oreMinutiInizio = inizio.split(":");
        const fineDate = new Date(props.giorno);
        fineDate.setHours(oreMinutiFine[0]);
        fineDate.setMinutes(oreMinutiFine[1]);
        const inizioDate = new Date(props.giorno);
        inizioDate.setHours(oreMinutiInizio[0]);
        inizioDate.setMinutes(oreMinutiInizio[1]);
        return fineDate > inizioDate;
    }

    return (
        <div className="formGroup" id="turnolavoratore">
            {message &&
                message.map((data, key) => (
                    <Alert variant={data.tipo} id="message" key={key}>
                        {data.descrizione}
                    </Alert>))}
            <div className="elementoTurno" id="lavoratore">
                <label className="inEvidenza">lavoratore</label>
                <Select
                    name="lavoratore"
                    instanceId="lavoratore"
                    options={props.lavoratori}
                    onChange={lavoratoreChanged}
                    placeholder="Seleziona..."
                    value={lavoratore}
                    isDisabled={props.lavoratoreDisable}
                />
            </div>
            <div className="elementoTurno">
                <label className="inEvidenza" id="sede">sede</label>
                <Select
                    name="sede"
                    instanceId="sede"
                    options={sediOptions()}
                    onChange={sedeChanged}
                    placeholder="Seleziona..."
                    value={sede}
                    isDisabled={props.sedeDisable}
                />
            </div>
            <div className="elementoTurno" id="postazione">
                <label className="inEvidenza">postazione</label>
                <Select
                    name="postazione"
                    instanceId="postazione"
                    options={postazioniOptions()}
                    onChange={postazioneChanged}
                    placeholder="Seleziona..."
                    isDisabled={!sede || props.postazioneDisable}
                    value={postazione}
                />
            </div>
            <div className="elementoTurno" id="servizio">
                <label className="inEvidenza">servizio</label>
                <Select
                    name="servizio"
                    instanceId="servizio"
                    options={serviziOptions()}
                    onChange={servizioChanged}
                    placeholder="Seleziona..."
                    value={servizio} />
            </div>
            <div className="elementiTurno">
                <div className="timePicker" id="inizio">
                    <label className="inEvidenza">inizio</label>
                    <TimePicker
                        id="inizio"
                        disableClock={true}
                        clearIcon={null}
                        onChange={inizioChanged}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        value={inizio}
                    />
                </div>
                <div className="timePicker" id="fine">
                    <label className="inEvidenza">fine</label>
                    <TimePicker
                        id="fine"
                        disableClock={true}
                        clearIcon={null}
                        onChange={fineChanged}
                        hourPlaceholder="hh"
                        minutePlaceholder="mm"
                        value={fine}
                    />
                </div>
            </div>
            <div className="elementiTurno">
                <Button className="btnGenerale" variant="flat" size="sm" id="salvaturno"
                    onClick={salva}>Salva</Button>
                {
                    props.id && <Button className="btnGenerale" variant="flat" size="sm" id="elimina"
                        onClick={elimina}>Elimina</Button>
                }
                <Button className="btnGenerale" variant="flat" size="sm" id="annulla"
                    onClick={props.close}>Annulla</Button>
            </div>
        </div>
    )
}
