import React from 'react';
import Tabella730 from '../Campagna730/Tabella730';

function NumeroCumulativoIsee() {
    return (
        <Tabella730 titolo="Numero cumulativo ISEE"
            url="numerocumulativoisee"
            nome='NUMERO_CUMULATIVO_ISEE'
            type="numerocumulativoisee"
            format={true} />
    )
}

export default NumeroCumulativoIsee