

import { getMillisec, getOreMinuti } from './turniHelper';
import { useEffect, useState } from 'react';

export function TotaleOre(props) {

    const [index, setIndex] = useState('')

    useEffect(() => {
        setIndex(props.index)
    }, []);


    const getTotale = () => {
        var totaleMsec = 0;
        props.turni.map((turno, keyTurno) => (
            totaleMsec += getMillisec(turno.inizio, turno.fine)
        ))
        return getOreMinuti(totaleMsec);
    }


    return (<>
            <div className='inEvidenza' id={index}>
                {getTotale()}
            </div>
    </>)
}