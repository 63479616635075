import React, { useEffect, useState } from 'react'
import { Button, Container, Alert } from "react-bootstrap"
import { getUserFromStorage } from '../helper/handler'
import { buildUrl } from "../helper/handler"
import { getFromApi } from '../utilities/NetUtilities';
import { useHistory } from 'react-router-dom'

function LandingPage() {

    const [userLogged, setUserLogged] = useState("");
    const [message, setMessage] = useState({})
    const history = useHistory();

    useEffect(() => {
        window.sessionStorage.removeItem("organizzazione")
        const user = getUserFromStorage();
        setUserLogged(user)
        if (Object.values(user.organizzazioni).length < 1) {
            setMessage({ tipo: "warning", descrizione: "chiedere all'amministratore di assegnare almeno un'organizzazione" })
        }
    }, [])

    const action = (data) => {
        window.sessionStorage.setItem("organizzazione", JSON.stringify(data.organizzazione))
        history.push("/riepilogogenerale")
    }

    const startAnalyses = (organizzazione) => {
        const paramsObject = {
            organizzazione: organizzazione.nome,
            tipo: organizzazione.tipo
        }
        const url = buildUrl('checkorganizzazione', paramsObject)
        getFromApi(url, action, setMessage);
    }

    return (
        <div className='main' role="main">
            <Alert variant={message.tipo} id="message" >
                {message.descrizione}
            </Alert>
            <h1 className="d-flex justify-content-center" id="title_home">Home</h1>
            {userLogged && userLogged.organizzazioni.length > 0 &&
                <Container
                    className="justify-content-center">
                    <h2 className="d-flex justify-content-center space" id="scegliOrgUser">scegli l'organizzazione su cui vuoi operare</h2>
                    <div className="space containerorg">
                        {userLogged && userLogged.organizzazioni.map(organizzazione => {
                            return (
                                <Button key={organizzazione.nome} id={organizzazione.nome} data-testid={organizzazione.nome} type="button"
                                    variant='flat' className="btnGenerale btn-lg btn-block"
                                    onClick={(event) => { startAnalyses(organizzazione) }}>{organizzazione.nome} / {organizzazione.tipo}</Button>
                            );
                        })}
                    </div>
                </Container>
            }
        </div>
    );
}

export default LandingPage;
