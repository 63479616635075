import React, { useEffect, useState } from 'react';
import { daMinutiAOre } from './turniHelper';

function TabellaServizio(props) {
    const [tabellaServizio, setTabellaServzio] = useState(null)
    const [totMinutiServizio, setTotMinutiServizio] = useState(0)
    useEffect(() => {
        setTabellaServzio(props.tabellaServizio)
        setTotMinutiServizio(props.minutiTotServizio);
    }, [props.tabellaServizio])

    const printRigha = (servizio, minuti, indexServizo) => {
        return (<tr>
                    <td className='cellaSpaziosa' id={'servizio-' + indexServizo}>{servizio}</td>
                    <td className="center cellaSpaziosa" id={'oreservizio-' + indexServizo}>{daMinutiAOre(minuti)}</td>
                </tr>
        )
    }

    const printTabella = () => {
        return (Object.keys(tabellaServizio).map((servizio, indexServizo) => {
            var minuti = tabellaServizio[servizio];
            return (printRigha(servizio, minuti, indexServizo))
        }))
    }

    const printTotale = () => {
       
        return (<tr>
            <td id={'totservizio'} className="inEvidenza cellaSpaziosa right">totale ore</td>
            <td id={'oretotservizio'} className="inEvidenza cellaSpaziosa center">{daMinutiAOre(totMinutiServizio)}</td>
        </tr>
)
    }

    return (
        <div id="tabellaservizio" className='table-responsive'>
        <h2 className='inEvidenza'>Totale servizio</h2>
            {tabellaServizio && <table className='table-bordered bottom-space' >
                <thead  className="App">
                    <tr key="intestazione">
                        <th className="inEvidenza cellaSpaziosa">servizio</th>
                        <th className="inEvidenza cellaSpaziosa">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                    {printTabella()}
                    {printTotale()}
                </tbody>
            </table>}
        </div>
    )
}

export default TabellaServizio;