import React from 'react';

function Talk(props) {
    var messaggi = "";
    var warning = "";
    var errori = "";
    if (props.errori != null && props.errori.length > 0) {
        errori = props.errori.map((item) =>
            <div key="errori" className="row alert alert-danger alert-dismissable alertclass">{item}</div>
        );
    } else {
        if (props.messaggi != null && props.messaggi.length > 0) {
            messaggi = props.messaggi.map((item) =>
                <div key="messaggi" className="row alert alert-info alert-dismissable alertclass">{item}</div>
            );
        }
        if (props.warning != null && props.warning.length > 0) {
            warning = props.warning.map((item) =>
                <div key="warning" className="row alert alert-warning alert-dismissable alertclass">{item}</div>
            );
        }

    }
    return (
        <div data-testid="talk-manager" className="row" id="talk_message">
            {errori}
            {messaggi}
            {warning}
        </div>
    )
}

export default Talk;