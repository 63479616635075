
import React, { useEffect, useState } from 'react'
import moment from "moment";

function FiltriReport(props) {

    const [inizio, setInizio] = useState(null)
    const [fine, setFine] = useState(null)

    useEffect(() => {
        if(inizio === null) {
            var currentYear = new Date().getFullYear().toString()
            var startofYear = moment(currentYear).day(1).month(0).format("YYYY-MM-DD");
            setInizio(startofYear);
            props.setInizio(startofYear)
        }
        if(fine === null) {
            var oggi = moment(new Date()).format("YYYY-MM-DD");
            setFine(oggi);
            props.setFine(oggi)
        }
    }, [])

    function setInizioValue(dataInizio) {
        props.setMessage([]);
        if(checkInizioFine(dataInizio, fine)) {
            setInizio(dataInizio);
            props.setInizio(dataInizio);
        } else {
            setInizio(dataInizio);
            props.setMessage([{ tipo: "danger", descrizione: "la data d'inizio deve essere antecedente a quella di fine"}]);
        }
    }

    function setFineValue(dataFine) {
        props.setMessage([]);
        if(checkInizioFine(inizio, dataFine)) {
            setFine(dataFine);
            props.setFine(dataFine);
        } else {
            setFine(dataFine);
            props.setMessage([{ tipo: "danger", descrizione: "la data d'inizio deve essere antecedente a quella di fine"}]);
        }
    }

    const checkInizioFine = (inizio, fine) => {
        return fine > inizio;
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <label htmlFor="inizio" className="inEvidenza">data inizio</label>
                    <input
                        className='form-control'
                        id="inizio"
                        type="date"
                        value={inizio}                        
                        onChange={(event) => {
                            setInizioValue(event.target.value);
                        }}
                    />
                </div>
                <div className="col-lg-6 col-md-6">
                    <label htmlFor="fine" className="inEvidenza">data fine</label>
                    <input
                        className='form-control'
                        id="fine"
                        type="date"
                        value={fine}
                        onChange={(event) => {
                                setFineValue(event.target.value);
                            }}
                    />
                </div>
            </div>
        </div>
    )
}

export default FiltriReport;