import React from 'react';
import TabellaGenerale from '../TabellaGenerale';

function AreaLocazioni() {
    return (
        <TabellaGenerale titolo="Area locazioni" url="arealocazioni" nome="Locazioni" numTabella={3} />
    );
};

export default AreaLocazioni;
