import React from 'react';
import TabellaGenerale from '../TabellaGenerale';

function AreaImu() {
    return (
        <TabellaGenerale titolo="Area imu" url="areaimu" nome="Imu" numTabella={3} />
    );
};

export default AreaImu;
