import React from 'react';
import TabellaProduzionePerSede from './TabellaPeoduzionePerSede';

function ProduzionePerSede() {
    return (
        <TabellaProduzionePerSede titolo="Produzione Per Sede"
            url="produzionepersede"
            type="produzionepersede" />
    )
}

export default ProduzionePerSede