import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap"
import Select from 'react-select';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities'
import { Settimane } from '../helper/settimaneTurniLavoro';


function FiltriPerSede(props) {

    const [sedi, setSedi] = useState([])
    const [sede, setSede] = useState(null)
    const [settimana, setSettimana] = useState(null)

    var settimane = Settimane;

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setSediFromApi = (data) => {
            setSedi(data.sedi.map(sede => ({
                "value": sede.id,
                "label": sede.descrizione
            })));
        }
        const setMessageFromApi = (data) => {
            props.setMessage(message => [...message, {tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione}])
        }
        getFromApi(buildUrl('settimanaCorrenteTurniLavoro'), setSettimana, props.setMessage);
        if(organizzazioneMap) {
            const url = buildUrl('sedi', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo});
            getFromApi(url, setSediFromApi, setMessageFromApi);        
        }
    }, [props.setMessage]);

    const filtroChanged = (newSede, newSettimana) => {
        if (newSede && newSettimana) {
            props.filtroChanged(newSede, newSettimana.value);
        }
    }

    const settimanaChanged = (newSettimana) => {
        setSettimana(newSettimana);
        filtroChanged(sede, newSettimana);
    }
    const sedeChanged = (newSede) => {
        setSede(newSede);
        filtroChanged(newSede, settimana);
    }


    return (
        <Row className='filtri'>
            <Col xl={3} sm={4} xs={12} id="sedefiltro">
                <p className="inEvidenza">sede</p>
                <Select
                    name="sede"
                    instanceId="sede"
                    options={sedi}
                    onChange={sedeChanged}
                    placeholder="Seleziona..."
                    value={sede}
                />
            </Col>
            <Col xl={6} sm={8} xs={12} id="settimanafiltro">
                <p className="inEvidenza">settimana</p>
                <Select
                    name="settimanainizio"
                    instanceId="settimana"
                    options={settimane}
                    onChange={settimanaChanged}
                    placeholder="Seleziona..."
                    value={settimana}
                />
            </Col>
        </Row>
    )
}

export default FiltriPerSede;