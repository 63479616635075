import React from 'react';
import Tabella730 from '../Campagna730/Tabella730';

function NumeroSettimanaleIsee() {
    return (
        <Tabella730 titolo="Numero settimanale ISEE"
            url="numerosettimanaleisee"
            nome='NUMERO_SETTIMANALE_ISEE'
            type="numerosettimanaleisee"
            format={true} />
    )
}

export default NumeroSettimanaleIsee