import React from 'react';
import Tabella730 from './Tabella730';

function NumeroSettimanale730InModifica() {
    return (
        <Tabella730 titolo="Numero settimanale 730 in modifica"
            url="numerosettimanale730inmodifica"
            nome='NUMERO_SETTIMANALE_730_IN_MODIFICA'
            format={true}
            type="numerosettimanale730inmodifica"
            numero730inmodifica={true} />
    )
}

export default NumeroSettimanale730InModifica