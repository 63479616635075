import React from 'react';
import {Spinner} from 'react-bootstrap'

export const SpinnerComp = () => {
    return(
        <div className="spinner" data-testid="spinner">
            <p>Caricamento in corso</p>
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
        </div>
    )
}