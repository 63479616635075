import React, { useState, useEffect } from 'react';
import { getDatiFattura } from '../handlers/datiFatturaHandler';
import { SpinnerComp } from '../helper/spinner';
import Talk from '../helper/Talk';
import { Container } from 'react-bootstrap'
import Tabella from '../Tabella';
import { buildUrl } from "../helper/handler"
import { getFromApi } from '../utilities/NetUtilities';

function TabellaProduzionePerSede(props) {
    const [dati, setDati] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [nomeOrganizzazione, setNomeOrganizzazione] = useState("");
    const [tipoOrganizzazione, setTipoOrganizzazione] = useState("");    

    useEffect(() => {
        const connessioneTabella730 = (organizzazioneNome, tipo, tutti) => {
            const okAction = (data) => {
                setLoading(false);
                const {table, errore} = 
                getDatiFattura( data, 
                                props.format, 
                                props.type, 
                                props.nome, 
                                props.numero730inmodifica,
                                organizzazioneNome, 
                                tipo, 
                                tutti);
                setDati(table);
                setError(errore);
            }
            const nokAction = (data) => {
                console.log("data", data)
                console.log("campagna730 errore", data.messaggi[0].descrizione)
                setDati([]);
                setError(data.messaggi[0].descrizione);
                setLoading(false);    
            }
            const params = {
                organizzazione: organizzazioneNome,
                tipo: tipo,
                tutti: tutti
            };
            const url = buildUrl('produzionepersede', params)
            console.log(url)
            getFromApi(url, okAction, nokAction);
        }
        setLoading(true);
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        setNomeOrganizzazione(organizzazioneMap.nome);
        setTipoOrganizzazione(organizzazioneMap.tipo);
        if (organizzazioneMap) {
            connessioneTabella730(organizzazioneMap.nome, organizzazioneMap.tipo, false);
        }
    }, [props.format, props.nome, props.numero730inmodifica, props.type]);

    
        if (loading) {
            return <SpinnerComp />
        }
        return(
            <div className='main'>
                <Container fluid>
                    <h1 className="d-flex justify-content-center">
                        {props.titolo} ({nomeOrganizzazione.toLowerCase()}/{tipoOrganizzazione.toLowerCase()})</h1>
                    <Talk errori={error}/>
                    <>
                        {dati.map((dati, key)=> (
                            <div className="divTabel" key={key}>
                                {dati.button}
                                <Tabella 
                                open={true} 
                                rows={dati.rows} 
                                intestazione= {dati.intestazione} 
                                nomeTabella={dati.name}/>
                            </div>
                        ))}
                    </>
                </Container>
            </div>)
}

export default TabellaProduzionePerSede