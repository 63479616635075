import React, { useEffect, useState, useRef } from 'react'
import { buildUrl, removeUserFromStorage } from '../helper/handler'
import { Link, useLocation } from 'react-router-dom';
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import logo from '../logoacli.jpg';
import { getFromApi } from '../utilities/NetUtilities';

function Reset() {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const random = query.get('random')

    const [message, setMessage] = useState({ tipo: "", descrizione: "" })
    const newPwdRef = useRef()
    const confirmPwdRef = useRef()
    const [validate, setValidate] = useState(false)

    useEffect(() => {
        const url = buildUrl('checkResetPassword', { random: random })
        const okAction = (data) => { setValidate(true); }
        const errorAction = (data) => { setMessage(data.messaggi[0]); }
        getFromApi(url, okAction, errorAction);
    }, [random])

    const resetPwd = (random, newPwd) => {
        const paramsObject = {
            random: random,
            password: newPwd
        }
        const url = buildUrl('confirmResetPassword', paramsObject);
        const action = (data) => {
            setMessage(data.messaggi[0]);
            setValidate(false);
            removeUserFromStorage();
         }
        getFromApi(url, action, action);
    }

    const submitResetPwd = event => {
        event.preventDefault();
        if (newPwdRef.current.value === confirmPwdRef.current.value) {
            resetPwd(random, newPwdRef.current.value);
        } else {
            setMessage({ tipo: "danger", descrizione: "le password non coincidono" })
        }
    }

    return (
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}>
            <div className="w-100" style={{ maxWidth: "500px" }}>
                {message && <Alert variant={message.tipo} id="message">{message.descrizione}</Alert>}
                <Card>
                    <Card.Body>
                        <Card.Img variant="top" src={logo} />
                        {validate && (<>
                            <h3 className='text-center mt-3'>Inserire nuova password</h3>
                            <Form className="mt-3" onSubmit={submitResetPwd}>
                                <Form.Group>
                                    <Form.Label>password</Form.Label>
                                    <Form.Control type="password" required ref={newPwdRef} data-testid="newPwd" id="newPwd" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>conferma password</Form.Label>
                                    <Form.Control type="password" required ref={confirmPwdRef} data-testid="confirmPwd" id="confirmPwd" />
                                </Form.Group>
                                <Button className='btnGenerale w-100' variant="flat" type="submit" data-testid="conferma" id="conferma">conferma</Button>
                            </Form>
                        </>)}
                        <div className="w-100 text-center mt-3">
                            <Link to="/" data-testid="annullaReset" id="annulla">torna al login</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    );
}
export default Reset;
