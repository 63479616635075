import React, { useEffect, useState } from 'react';
import { daMinutiAOre } from './turniHelper';

function TabellaLavoratore(props) {

    const [tabellaLavoratore, setTabellaLavoratore] = useState(null)
    const [totMinutiLavoratore, setTotMinutiLavoratore] = useState({})

    useEffect(() => {
        setTabellaLavoratore(props.tabellaLavoratore)
        setTotMinutiLavoratore(props.totMinutiLavoratore);
    }, [props.tabellaLavoratore])

    const printRigheSedi = (tabellaSedi, lavoratore, servizio, indexLavoratore, indexServizio, nRighe, nRigheServizio) => {
        if(Object.keys(tabellaSedi).length === 0) {
            return (
                <tr id={indexLavoratore - indexServizio}>
                    {indexServizio === 0 && <td rowSpan={nRighe+1} id={'lavoratore-' + indexLavoratore + '-' + indexServizio} className="cellaSpaziosa inEvidenza">{lavoratore}</td>}
                    {<td className='cellaSpaziosa' rowSpan={nRigheServizio} id={'servizio-' + indexLavoratore + '-' + indexServizio}>{servizio}</td>}
                    <td></td>
                    <td className="center cellaSpaziosa">{daMinutiAOre(0)}</td>
                </tr>
)
        } else {
        return (
            Object.keys(tabellaSedi).map((sede, indexSede) => {
                const minuti = tabellaSedi[sede];
                return (
                    <tr id={indexLavoratore - indexServizio - indexSede}>
                        {indexServizio === 0 && indexSede === 0 && <td rowSpan={nRighe+1} id={'lavoratore-' + indexLavoratore + '-' + indexServizio} className="inEvidenza">{lavoratore}</td>}
                        {indexSede === 0 && <td rowSpan={nRigheServizio} id={'servizio-' + indexLavoratore + '-' + indexServizio}>{servizio}</td>}
                        <td className='cellaSpaziosa'>{sede}</td>
                        <td className="center cellaSpaziosa">{daMinutiAOre(minuti)}</td>
                    </tr>

                )
            }
            ))}

    }
    const printRighe = (tabellaServizi, lavoratore, indexLavoratore, nRighe) => {
        console.log("lavoratore nRighe", lavoratore, nRighe)
        return (
            Object.keys(tabellaServizi).map((servizio, indexServizio) => {
                var tabellaSedi = tabellaServizi[servizio]
                var nRigheServizio = 1;
                if (Object.keys(tabellaSedi).length !== 0) {
                    nRigheServizio = Object.keys(tabellaSedi).length;
                }
                return (
                        printRigheSedi(tabellaSedi, lavoratore, servizio, indexLavoratore, indexServizio, nRighe, nRigheServizio)
                )
            }))

    }
    const countRighe = (tabellaServizi, nRighe) => {
        var nRighe = 0;
        (Object.keys(tabellaServizi).map((servizio, indexServizio) => {
            var tabellaSedi = tabellaServizi[servizio];
            if (Object.keys(tabellaSedi).length === 0) {
                nRighe = nRighe + 1;
            } else {
                nRighe = nRighe + Object.keys(tabellaSedi).length;
            }
        }))
        return nRighe;
    }


    const printTabella = () => {
        return (Object.keys(tabellaLavoratore).map((lavoratore, indexLavoratore) => {
            var tabellaServizi = tabellaLavoratore[lavoratore];
            var nRighe = countRighe(tabellaServizi);
            var minutiTot = totMinutiLavoratore[lavoratore]
            return (<>{printRighe(tabellaServizi, lavoratore, indexLavoratore, nRighe)}
                    {<tr>
                        <td></td>
                        <td className="inEvidenza cellaSpaziosa right">totale ore</td>
                        <td className="inEvidenza cellaSpaziosa center" id={'totorelavoratore-' + indexLavoratore}>{daMinutiAOre(minutiTot)}</td>
                    </tr>}</>
            )
        }))
    }


    return (
        <div id="tabellalavoratore" className='table-responsive'>
        <h2 className='inEvidenza'>Totale lavoratore</h2>
            {tabellaLavoratore && <table className="table-bordered bottom-space" >
                <thead  className="App">
                    <tr key="intestazione">
                        <th className="inEvidenza cellaSpaziosa">lavoratore</th>
                        <th className="inEvidenza cellaSpaziosa">servizio</th>
                        <th className="inEvidenza cellaSpaziosa">sede</th>
                        <th className="inEvidenza cellaSpaziosa">totale (hh:mm)</th>
                    </tr>
                </thead>
                <tbody>
                    {printTabella()}
                </tbody>
            </table>}
        </div>
    )
}

export default TabellaLavoratore;