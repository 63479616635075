import React from 'react';
import Tabella730 from '../Campagna730/Tabella730';

function RiepiloghiIsee() {
    return (
        <Tabella730 titolo="Riepiloghi ISEE"
            url="tabelleriepilogativeisee"
            type="riepilogoIsee" />
    )
}

export default RiepiloghiIsee