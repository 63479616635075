
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap"


function MenuFunzionalita() {
    return (
        <div className="row">
            <Link to="/orariolavorolavoratore">
                <Button key="turnilavoratore" id="turnilavoratore" type="button"
                    variant='flat' className="btnGenerale btn-lg btn-block menubutton">Turni per lavoratore</Button> 
            </Link>
            <Link to="/orariolavorosede">
                <Button key="turnisede" id="turnisede" type="button"
                    variant='flat' className="btnGenerale btn-lg btn-block menubutton">Turni per sede</Button> 
            </Link>
            <Link to="/orariolavorosettimana">
                <Button key="turnisettimana" id="turnisettimana" type="button"
                    variant='flat' className="btnGenerale btn-lg btn-block menubutton" 
                    >Turni per settimana</Button>   
            </Link>                    
        </div>
    )

}

export default MenuFunzionalita;