
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TurnoLavoratore } from "./TurnoLavoratore";
import { FaPlus } from 'react-icons/fa';
import { useRef, useEffect, useState } from 'react';
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';

export function TurniGiornoSede(props) {

    const [lavoratori, setLavoratori] = useState([])
    const [indexAggiungi, setIndexAggiungi] = useState('')
    const [indexTurno, setIndexTurno] = useState('')
    const ref = useRef();  
    const closeTooltip = () => ref.current.close();

    const getSedeTurno = (sedeId) => {
        const filtered =  props.sedi.filter((sede) => sede.id === sedeId);
        return filtered.length >0 ? filtered[0].id : "";
    } 
    const [sede] = useState(getSedeTurno(props.sede.value))

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setLavoratoriFromApi = (data) => {
            setLavoratori(data.lavoratori.map(lavoratore => ({
                "value": lavoratore.id,
                "label": lavoratore.nomeCognome
            })));
        }
        const setMessageFromApi = (data) => {
            props.setMessage(message => [...message, {tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione}])
        }
        if(organizzazioneMap) {
            getFromApi(buildUrl('lavoratori', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setLavoratoriFromApi, setMessageFromApi);
        }
        setIndexAggiungi(props.index);
        setIndexTurno(props.index + "turno");
    }, [props]);

    const addTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, 
                {descrizione: value.messaggio, tipo: value.tipo.toLowerCase() }])
        ))
        for( var i = 0; i < props.turni.length; i++){ 
            if ( props.turni[i].id === data.turno.id) {
                props.turni.splice(i, 1); 
            }
        }
        props.turni.push(data.turno);
        props.turni.sort(function(a,b) {
            return a.inizio > b.inizio
        })
        closeTooltip();
    }

    const eliminaTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, 
                {descrizione: value.messaggio, tipo: value.tipo.toLowerCase() }])
        ))
        for( var i = 0; i < props.turni.length; i++){ 
            if ( props.turni[i].id === data.id) {
                props.turni.splice(i, 1); 
            }
        }
        closeTooltip();
    }

    const formatServizio = (servizio) => {
        return props.servizi[servizio];
    }

    const formatTurno = (turno) => {
        const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
        const label = lavoratore !== null ? lavoratore.label : "";
        return turno.inizio + "-"
            + turno.fine + " "
            + label + ": "
            + formatServizio(turno.servizioId)
    }

    const getLavoratoreTurno = (lavoratoreId) => {
        const filtered =  lavoratori.filter((lavoratore) => lavoratore.value === lavoratoreId);
        return filtered.length >0 ? filtered[0] : null;
    } 

    const getPopup = (trigger, turno, cssClass, index) => {
        const lavoratore = getLavoratoreTurno(turno.lavoratoreId);
        return (<Popup
            ref={ref}
            trigger={<button className={cssClass} id={index} key={index}>{trigger}</button>}
            position="bottom center"
            modal>{
                close =>
                    <TurnoLavoratore
                        id={turno.id}
                        sede={turno.sedeId}
                        inizio={turno.inizio}
                        fine={turno.fine}
                        postazione={turno.postazione}
                        servizio={turno.servizioId}
                        sedi={props.sedi}
                        servizi={props.servizi}
                        lavoratore={lavoratore}
                        giorno={props.giorno}
                        mattina={props.mattina}
                        action={addTurno}
                        eliminaAction={eliminaTurno}
                        close={close} 
                        lavoratori={lavoratori}
                        lavoratoreDisable={props.lavoratoreDisable}
                        postazioneDisable={props.postazioneDisable}
                        sedeDisable={props.sedeDisable}
                    />
            }
        </Popup>)
    }

    return (<>
        {getPopup(<FaPlus />, {id: "", sedeId: sede, postazione: props.postazione}, 'aggiungiTurno', indexAggiungi)}
        {props.turni.map((turno, keyTurno) => (
            <div className='turno' key={keyTurno}>{
                getPopup(formatTurno(turno), turno, 'triggerTurno', indexTurno)
            }</div>
        ))}
    </>)
}
