import React, { useEffect, useState } from 'react'
import MenuFunzionalita from "./MenuFunzionalita";
import FiltriPerSede from "./FiltriPerSede";
import TabellaOrarioPerSede from "./TabellaOrarioPerSede";
import { Container, Alert } from "react-bootstrap";
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';

function OrarioLavoroSede() {

    const [message, setMessage] = useState([])
    const [filtro, setFiltro] = useState(null)
    const [servizi, setServizi] = useState();

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setServiziFromApi = (data) => {
            setServizi(data.servizi);
        }
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, {tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione}])
        }
        if(organizzazioneMap) {
            getFromApi(buildUrl('servizi', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo}), setServiziFromApi, setMessageFromApi)
        }
    }, [])


    function filtroChanged(newSede, newSettimana) {
        setFiltro({
            sede: newSede,
            settimana: newSettimana
        })
    }

    return (        
        <div className='main' role="main">
            <div>
                <MenuFunzionalita />
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Turni di lavoro per sede</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))}
                <Container className="">
                    <FiltriPerSede setMessage={setMessage} filtroChanged={filtroChanged} />
                </Container>
                {filtro && <Container fluid>
                    <TabellaOrarioPerSede
                        setMessage={setMessage}
                        settimana={filtro.settimana}
                        sede={filtro.sede}
                        servizi={servizi}
                    />
                </Container>}
               
            </div>
        </div>
    )
}

export default OrarioLavoroSede;