
import moment from "moment";

export const getSede = (sedi, sedeId) => {
    const filtered = sedi.filter((sede) => sede.id === sedeId);
    return filtered.length === 1 ? filtered[0] : {postazioni: []};
}

export const getPostazioneDesc = (postazioni, postazioneId) => {
    return postazioni[postazioneId];
}

export const getMillisec = (inizio, fine) => {
    const oreMinutiFine = fine.split(":");
    const oreMinutiInizio = inizio.split(":");
    var fineDate = new Date();
    fineDate.setHours(oreMinutiFine[0]);
    fineDate.setMinutes(oreMinutiFine[1]);
    var inizioDate = new Date();
    inizioDate.setHours(oreMinutiInizio[0]);
    inizioDate.setMinutes(oreMinutiInizio[1]);  
    return fineDate.getTime()-inizioDate.getTime();
}

export const getOreMinuti = (msec) => {
    var minuti = msec / (1000 * 60);
    return daMinutiAOre(minuti);
}

export const daMinutiAOre = (minuti) => {
    var ore = Math.round(minuti/60);
    var minutiRimanenti = minuti - ore*60;
    var formatOre;
    if(ore >=10) {
        formatOre = ore;
    } else {
        formatOre = ("0" + ore).slice(-2)
    }
    return formatOre + ":" + ("0" + minutiRimanenti).slice(-2)
}
