import React, {useState} from 'react';
import {Button} from 'react-bootstrap'
import Tabella from '../Tabella';

function TabellaCollassabile (props) {
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState('100%');

    const handlerChange = () => {
        setOpen(!open)
        props.setLarghezza()
        if (!props.type.startsWith("riepilogo")) {
            setWidth('613%')
        }
	};

    if (open) {
        var downloadbutton = <div className="buttonDownloadCampagna" key={props.button}>{props.button}</div>
    }
    return(
        <div className="tabella col">
            <div className="row" style={{width: `${width}`}}>
                <div className="divDropdownbutton ml-3">
                    <Button onClick={handlerChange} className="dropdown-toggle btnGenerale" id={props.nomeTabella}
                        variant="" size='sm' 
                        aria-controls="espandere tabella" aria-expanded={open} key={props.nomeTabella}>{props.nomeTabella}
                    </Button>
                </div>
                {downloadbutton}
            </div>
            <Tabella rows={props.rows} 
                intestazione={props.intestazione} 
                open={open} 
                numeri730 = {props.numeri730}
                numeriIsee = {props.numeriIsee}
                nomeTabella={props.nomeTabella}/>
    </div>
    )
}

export default TabellaCollassabile;