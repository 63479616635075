import React from 'react';
import Tabella730 from '../Campagna730/Tabella730';

function NumeroSettimanaleIseeInModifica() {
    return (
        <Tabella730 titolo="Numero settimanale ISEE in modifica"
            url="numerosettimanaleiseeinmodifica"
            nome='NUMERO_SETTIMANALE_ISEE_IN_MODIFICA'
            format={true}
            type="numerosettimanaleiseeinmodifica"
            numero730inmodifica={true} />
    )
}

export default NumeroSettimanaleIseeInModifica