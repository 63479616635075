import React from 'react';
import Tabella730 from './Tabella730';

function FatturatoCumulativo730() {
    return (
        <Tabella730 
            titolo="Fatturato cumulativo 730" 
            url="fatturatocumulativo730"
            nome="FATTURATO_CUMULATIVO_730"
            type="fatturatocumulativo730"
        />
    );
};

export default FatturatoCumulativo730;
