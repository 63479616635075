import { useEffect } from 'react';
import RigheSede from "./RigheSede.js";


function RigheTabellaGiorno(props) {


    useEffect(() => {
    }, []);


    return( 
        props.sedi && Object.keys(props.turni).map((sedeId) => {
            return (
                <RigheSede 
                    sedi={props.sedi}
                    servizi={props.servizi}
                    lavoratori={props.lavoratori}
                    sedeId={sedeId}
                    turni={props.turni[sedeId]}
                    index={props.index + sedeId}
                    setMessage={props.setMessage}
                    data={props.data}
                />
            )
        }))
}

export default RigheTabellaGiorno;