import React from 'react';
import Tabella730 from './Tabella730';

function NumeroCumulativo730() {
    return (
        <Tabella730 titolo="Numero cumulativo 730"
            url="numerocumulativo730"
            nome='NUMERO_CUMULATIVO_730'
            type="numerocumulativo730"
            format={true} />
    )
}

export default NumeroCumulativo730