
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TurnoLavoratore } from "./TurnoLavoratore";
import { FaPlus } from 'react-icons/fa';
import { useRef, useEffect, useState } from 'react';
import { getSede } from './turniHelper';

export function TurniGiorno(props) {
    const [indexAggiungi, setIndexAggiungi] = useState('')
    const [indexTurno, setIndexTurno] = useState('')

    const ref = useRef();  
    const closeTooltip = () => ref.current.close();

    useEffect(() => {
        setIndexAggiungi(props.index);
        setIndexTurno(props.index + "turno");
    }, [props.index]);

    const addTurno = (data) => {
        props.setMessage([])
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, {
                descrizione: value.messaggio,
                tipo: value.tipo.toLowerCase()
            }])
        ))
        for( var i = 0; i < props.turni.length; i++){ 
            if ( props.turni[i].id === data.turno.id) {

                props.turni.splice(i, 1); 
            }
        }
        props.turni.push(data.turno);
        props.turni.sort(function(a,b) {
            return a.inizio > b.inizio
        })
        closeTooltip();
    }

    const elimnaTurno = (data) => {
        data.messaggi.map((value, key) => (
            props.setMessage(message => [...message, 
                {descrizione: value.descrizione, tipo: value.tipo }])
        ))
        for( var i = 0; i < props.turni.length; i++){ 
            if ( props.turni[i].id === data.id) {
                props.turni.splice(i, 1); 
            }
        }
        closeTooltip();
    }

    const formatPostazione = (sedeId, postazione) => {
        const sede = getSede(props.sedi, sedeId);
        var postazioni = sede.postazioni
        var postazioneDesc = "";
        if (postazioni) {
            postazioneDesc = postazioni[postazione];
        } 
        return sede ? sede.descrizione + "/" + postazioneDesc : "sconosciuta";
    }

    const formatServizio = (servizio) => {
        return props.servizi[servizio];
    }

    const formatTurno = (turno) => {
        return turno.inizio + "-"
            + turno.fine + " "
            + formatPostazione(turno.sedeId, turno.postazione) + ": "
            + formatServizio(turno.servizioId)
    }

    const getPopup = (trigger, turno, cssClass, index) => {
        return <Popup
            ref={ref}
            trigger={<button className={cssClass} id={index}>{trigger}</button>}
            position="bottom center"
            modal>{
                close =>
                    <TurnoLavoratore
                        id={turno.id}
                        sede={turno.sedeId}
                        inizio={turno.inizio}
                        fine={turno.fine}
                        postazione={turno.postazione}
                        servizio={turno.servizioId}
                        sedi={props.sedi}
                        servizi={props.servizi}
                        lavoratore={props.lavoratore}
                        giorno={props.giorno}
                        mattina={props.mattina}
                        action={addTurno}
                        eliminaAction={elimnaTurno}
                        close={close} 
                        lavoratori={props.lavoratori}
                        lavoratoreDisable={props.lavoratoreDisable}
                        postazioneDisable={props.postazioneDisable}
                        sedeDisable={props.sedeDisable}
                    />
            }
        </Popup>
    }

    return (<>
        {getPopup(<FaPlus />, {id: ""}, 'aggiungiTurno', indexAggiungi)}
        {props.turni.map((turno, keyTurno) => (
            <div className='turno' key={keyTurno}>{
                getPopup(formatTurno(turno), turno, 'triggerTurno', indexTurno)
            }</div>
        ))}
    </>)
}
