import React from 'react';
import Tabella730 from './Tabella730';

function FatturatoSettimanale730() {
    return (
        <Tabella730 titolo="Fatturato settimanale 730"
            url="fatturatosettimanale730"
            nome='FATTURATO_SETTIMANALE_730'
            type="fatturatosettimanale730"
        />
    )
}

export default FatturatoSettimanale730