import React from 'react';
import TabellaGenerale from '../TabellaGenerale';

function AreaCondomini() {
    return (
        <TabellaGenerale titolo="Area condomini" url="areacondomini" nome="Condomini" numTabella={2} />
    );
};

export default AreaCondomini;
