import React from 'react';
import TabellaGenerale from './TabellaGenerale';

function RiepilogoGenerale () {

    return (
            <TabellaGenerale titolo="Fatturato per codice tariffario" 
            url="fatturatopercodicetariffario" 
            riepilogogenerale={true} />
    )
}

export default RiepilogoGenerale