import React, { useEffect, useState } from 'react'
import { Container, Alert } from "react-bootstrap";
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';
import moment from "moment";
import FiltriReport from './FiltriReport';
import TabellaSede from './TabellaSede';
import TabellaServizio from './TabellaServizio';
import TabellaLavoratore from './TabellaLavoratore';

function ReportOrarioLavoro() {

    const [message, setMessage] = useState([])
    const [inizio, setInizio] = useState(null)
    const [fine, setFine] = useState(null)
    const [tabellaSede, setTabellaSede] = useState(null)
    const [minutiTotSede, setMinutiTotSede] = useState(null)
    const [tabellaServizio, setTabellaServizio] = useState(null)
    const [minutiTotServizio, setMinutiTotServizio] = useState(0)
    const [tabellaLavoratore, setTabellaLavoratore] = useState(null)
    const [totMinutiLavoratore, setTotMinutiLavoratore] = useState(null)

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setTabelle = (data) => {
            setTabellaSede(data.tabellasede);
            setMinutiTotSede(data.totminutisede);
            setTabellaServizio(data.tabellaservizio);
            setTabellaLavoratore(data.tabellalavoratore);
            setMinutiTotServizio(data.totminutiServizio);
            setTabellaLavoratore(data.tabellalavoratore);
            setTotMinutiLavoratore(data.totminutilavoratore)
            console.log("tabellaLavoratore", data.tabellalavoratore)
        }
        const setMessageFromApi = (data) => {
            setMessage(message => [...message, { tipo: data.messaggi[0].tipo, descrizione: data.messaggi[0].descrizione }])
        }
        if (organizzazioneMap && inizio && fine) {
            const url = buildUrl('report', {organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo, inizio:inizio, fine:fine})
            getFromApi(url, setTabelle, setMessageFromApi);
        }

    }, [inizio, fine])

    return (

        <div className='main' role="main">
            <div>
                <h1 className="d-flex justify-content-center inEvidenza" id="title_home">Report</h1>
                {
                    message && message.map((data, key) => (
                        <Alert variant={data.tipo} id="message" key={key}>
                            {data.descrizione}
                        </Alert>
                    ))}
                <Container className="">
                    <FiltriReport setMessage={setMessage} setInizio={setInizio} setFine={setFine} />
                </Container>
                <Container fluid>
                    <div className='row space'>
                        <div className="col-lg-4 col-md-4">
                            <TabellaSede tabellaSede={tabellaSede} minutiTotSede={minutiTotSede}/>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <TabellaLavoratore tabellaLavoratore={tabellaLavoratore} totMinutiLavoratore={totMinutiLavoratore}/>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <TabellaServizio tabellaServizio={tabellaServizio} minutiTotServizio={minutiTotServizio}/>  
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ReportOrarioLavoro;