import CryptoJS from 'crypto-js'

export const saveUserToStorage = (user) => {
    window.sessionStorage.setItem("user-logged", user);
}

export const removeUserFromStorage = () => {
    window.sessionStorage.removeItem("user-logged");
}

export const getUserFromStorage = () => {
    const encoded = window.sessionStorage.getItem("user-logged")
    return encoded === null || encoded === "null" ? null : decodeObject(encoded).document;
}

export const checkAdministrator = () => {
    var checked = false;
    const user = getUserFromStorage()
    if (user) {
        if (user.role === "AMMINISTRATORE" || user.role === "SUPER_AMMINISTRATORE") {
            checked = true
        }
    }
    return checked
}

export const getUrl = (apiname, username, password) => {
    var params = new URLSearchParams({
        username: username,
        password: password
    });
    return process.env.REACT_APP_BASE_URL + "api/" + apiname + `?${params.toString()}`;
}

export const buildUrl = (apiname, paramsObject = {}) => {
    var params = new URLSearchParams(paramsObject);
    return process.env.REACT_APP_BASE_URL + "api/" + apiname + `?${params.toString()}`;
}

// export const encodeObject = (decoded) => {
//     const text = JSON.stringify(decoded);
//     console.log("text", text);
//     const keyForCryptoJS = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);
//     const encoded = CryptoJS.AES.encrypt(text, keyForCryptoJS, {
//         mode: CryptoJS.mode.ECB,
//         padding: CryptoJS.pad.Pkcs7
//     });
//     console.log("encoded", encoded);
//     return JSON.stringify(encoded.ciphertext);
// }

const decodeObject = (encoded) => {
    var keyForCryptoJS = CryptoJS.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);
    var decodeBase64 = CryptoJS.enc.Base64.parse(encoded)
    var decryptedData = CryptoJS.AES.decrypt(
        {
            ciphertext: decodeBase64
        },
        keyForCryptoJS,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText)
}

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

export const buildUrlSelezionaOrganizzazione = (apiController, paramsObj) => {
    var params = new URLSearchParams(paramsObj);
    return process.env.REACT_APP_BASE_URL + apiController + `?${params}`;
}

export const buildUrlOrganizzazione = (id, apiController) => {
    const paramsObj = {
        id: id
    };
    var params = new URLSearchParams(paramsObj);
    return process.env.REACT_APP_BASE_URL + apiController + `?${params.toString()}`;
}

const mattina = "mattina-";
const pomeriggio = "pomeriggio-";

export const getIdTurnoMattina = (giorno, key, statoIdTurnoGiornoMattina) => {
    var idTurno = "";
    if (statoIdTurnoGiornoMattina.size !== 0) {
        console.log("giorno", giorno)
        console.log("key", key)
        var id = statoIdTurnoGiornoMattina.get(giorno);
        console.log("id", id)
        var idTrovato = id.get(mattina.concat(key));
        console.log("idTrovato", idTrovato)
        if (idTrovato !== null) {
            idTurno = idTrovato;
        }
    }
    return idTurno;
}

export const getIdTurnoPomeriggio = (giorno, key, statoIdTurnoGiornoPomeriggio) => {
    var idTurno = "";
    if (statoIdTurnoGiornoPomeriggio.size !== 0) {
        var id = statoIdTurnoGiornoPomeriggio.get(giorno);
        var idTrovato = id.get(pomeriggio.concat(key));
        if (idTrovato !== null) {
            idTurno = idTrovato;
        }
    }
    return idTurno;
}
export const getInizioMattina = (giorno, key, statoInizioGiornoMattina) => {
    var inizio = {
        label: "00:00",
        value: "00:00"
    }
    if (statoInizioGiornoMattina.size !== 0) {
        var inizioMattina = statoInizioGiornoMattina.get(giorno);
        var inizioTrovato = inizioMattina.get(mattina.concat(key));
        if (inizioTrovato !== null) {
            inizio.label = inizioTrovato;
            inizio.value = inizioTrovato;

        }
    }
    return inizio;
}

export const getFineMattina = (giorno, key, statoFineGiornoMattina) => {
    var fine = {
        label: "00:00",
        value: "00:00"
    }
    if (statoFineGiornoMattina.size !== 0) {
        var fineMattina = statoFineGiornoMattina.get(giorno);
        var fineTrovato = fineMattina.get(mattina.concat(key));
        if (fineTrovato !== null) {
            fine.label = fineTrovato;
            fine.value = fineTrovato;
        }
    }
    return fine;
}


export const getInizioPomeriggio = (giorno, key, statoInizioGiornoPomeriggio) => {
    var inizio = {
        label: "00:00",
        value: "00:00"
    }
    if (statoInizioGiornoPomeriggio.size !== 0) {
        var inizioPomeriggio = statoInizioGiornoPomeriggio.get(giorno);
        var inizioTrovato = inizioPomeriggio.get(pomeriggio.concat(key));
        if (inizioTrovato !== null) {
            inizio.label = inizioTrovato;
            inizio.value = inizioTrovato;
        }
    }
    return inizio;
}

export const getFinePomeriggio = (giorno, key, statoFineGiornoPomeriggio) => {
    var fine = {
        label: "00:00",
        value: "00:00"
    }
    if (statoFineGiornoPomeriggio.size !== 0) {
        var finePomeriggio = statoFineGiornoPomeriggio.get(giorno);
        var fineTrovato = finePomeriggio.get(pomeriggio.concat(key));
        if (fineTrovato !== null) {
            fine.label = fineTrovato;
            fine.value = fineTrovato;
        }
    }
    return fine;
}