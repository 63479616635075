
import React, { useEffect, useState } from 'react'
import 'reactjs-popup/dist/index.css';
import moment from "moment";
import { TurniGiorno } from "./TurniGiorno";
import { TotaleOre } from "./TotaleOre";
import { buildUrl } from '../helper/handler';
import { getFromApi } from '../utilities/NetUtilities';

function TabellaOrarioPerLavoratore(props) {

    const [turni, setTurni] = useState(null)
    const [lavoratore, setLavoratore] = useState(null)
    const [settimana, setSettimana] = useState(null)

    const isLoaded = () => lavoratore === props.lavoratore.value && settimana === props.settimana;

    useEffect(() => {
        const organizzazioneMap = JSON.parse(window.sessionStorage.getItem("organizzazione"));
        const setTurniFromApi = (data) => {
            setTurni(data.turni);
            setLavoratore(props.lavoratore.value);
            setSettimana(props.settimana);
        }
        const getTurni = (lavoratore, settimana) => {
            if (lavoratore && settimana && organizzazioneMap) {
                const url = buildUrl('turniPerLavoratore', { lavoratore: lavoratore, settimana: settimana, 
                    organizzazione : organizzazioneMap.nome,tipo:organizzazioneMap.tipo });
                    console.log("url", url)
                getFromApi(url, setTurniFromApi, props.setMessage);
            }
        }
        if(organizzazioneMap) {
            getTurni(props.lavoratore.value, props.settimana);
        }
    }, [props.lavoratore.value, props.settimana, props.setMessage]);

    const printTurni = () => {
        return (
            Object.keys(turni).map((data) => {
                var turniGiorno = turni[data];
                return (
                    <tr key={data}>
                        <td className="inEvidenza">{moment(data).format('dddd DD MMMM')}</td>
                        <td><TurniGiorno
                            setMessage={props.setMessage}
                            sedi={props.sedi}
                            servizi={props.servizi}
                            lavoratore={props.lavoratore}
                            giorno={data}
                            mattina={true}
                            turni={turniGiorno.mattina}
                            lavoratori={props.lavoratori}
                            lavoratoreDisable={true}
                            postazioneDisable={false}
                            sedeDisable={false}
                            index ={moment(data).format('dddd')+"mattina"}
                        /></td>
                        <td className='orario'>
                            <TotaleOre
                                turni={turniGiorno.mattina}
                                index ={moment(data).format('dddd')+"mattinatotore"}
                            />
                        </td>
                        <td><TurniGiorno
                            setMessage={props.setMessage}
                            sedi={props.sedi}
                            servizi={props.servizi}
                            lavoratore={props.lavoratore}
                            giorno={data}
                            mattina={false}
                            turni={turniGiorno.pomeriggio}
                            lavoratori={props.lavoratori}
                            lavoratoreDisable={true}
                            index ={moment(data).format('dddd')+"pomeriggio"}
                        /></td>
                        <td className='orario'>
                            <TotaleOre
                                turni={turniGiorno.pomeriggio}
                                index ={moment(data).format('dddd')+"pomeriggiototore"}
                            /></td>
                    </tr>
                )
            })
        )
    }

    return (
        <table className="table table-bordered space">
            <thead className="App tableTurniLavoro">
                <tr>
                    <th className="inEvidenza">giorno</th>
                    <th className="inEvidenza">mattina</th>
                    <th className="inEvidenza">totale (hh:mm)</th>
                    <th className="inEvidenza">pomeriggio</th>
                    <th className="inEvidenza">totale (hh:mm)</th>
                </tr>
            </thead>
            <tbody>
                {isLoaded() && turni && printTurni()}
            </tbody>
        </table>
    )
}

export default TabellaOrarioPerLavoratore;
