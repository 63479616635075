import React from 'react';
import Tabella730 from './Tabella730';

function Riepiloghi() {
    return (
        <Tabella730 titolo="Riepiloghi"
            url="tabelleriepilogative730"
            type="riepilogo" />
    )
}

export default Riepiloghi